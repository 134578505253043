<template>
  <div class='flex flex-row flex-wrap gap-4 justify-center p-4'>
    <div v-for='(item, index) in items' 
      :key='`item-${index}`'
      class='w-24 h-28 flex-grow flex flex-col justify-center rounded-xl bg-white shadow'
      :class='item.cssClass'
      @click='goToItem(item.routeName)'>
      <img 
        :src='item.iconImgUrl'
        class='block mx-auto'
        :class='item.iconImgCssClass' />
      <h2 
        class='text-center uppercase text-sm lg:text-xl cursor-pointer'
        :style='item.titleCssStyle'>{{ item.title }}</h2>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gbcc2023MainItems',
  components: {
  },
  props: [
    'items',
  ],
  methods: {
    goToItem (routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>
