<template>
  <div class='flex flex-row justify-between items-stretch gap-4 p-4'>
    <div class='w-full'>
      <div
        class='w-full h-full flex flex-col justify-center rounded-xl bg-white'
        :style='largeItem.cssStyle'
        @click='goToItemInternal(largeItem.routeName)'>
        <img 
          :src='largeItem.iconImgUrl'
          class='block mx-auto'
          :style='largeItem.iconImgCssStyle' />
        <h2 
          class='text-center uppercase text-base lg:text-xl cursor-pointer'
          :style='largeItem.titleCssStyle'>{{ largeItem.title }}</h2>
      </div>
    </div>
    <div class='w-1/2 flex-grow-0 flex-shrink-0 flex flex-col flex-wrap gap-2 justify-center'>
      <div v-for='(item, index) in smallItems' 
        :key='`item-${index}`'
        class='w-full px-2 lg:px-4 py-3 flex-grow flex flex-row justify-start gap-x-1 lg:gap-x-3 font-semibold items-center rounded-xl bg-white'
        :style='item.cssStyle'
        @click='goToItemExternal(item.routeName)'>
        <img 
          :src='item.iconImgUrl'
          class='block flex-grow-0 flex-shrink-0 h-8 w-8'
          :style='item.iconImgCssStyle' />
        <h2 
          class='text-left uppercase text-xs lg:text-lg cursor-pointer'
          :style='item.titleCssStyle'>{{ item.title }}</h2>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Gbcc2023MainSubItems',
  props: [
    'items',
  ],
  computed: {
    smallItems () {
      return this.items.filter(item => item.size !== 'large')
    },
    largeItem () {
      return this.items.find(item => item.size === 'large')
    }
  },
  methods: {
    goToItemInternal (routeName) {
      this.$router.push({ name: routeName })
    },
    goToItemExternal (path) {
      window.open(path, '_blank')
    },
  },
}
</script>
