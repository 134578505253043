<template>
  <div>
    <welcome-conference-banner v-if='false' 
      :show-scroll-arrows='false'
      :show-dots='false'
      background-color='#F9F6F9' />
    <div class='relative pb-8'
      style='min-height:calc(100vh - 20rem);'>
      <content-background-cover
      class='z-0'
      :top-offset='420'
      custom-css='background:linear-gradient(180deg, #FFFFFF 0%, #FDF0FDAA 42.03%);' />
      <img
        class='block mx-auto w-full opacity-100 lg:opacity-95' 
        style='object-fit:cover; object-position:top;'
        src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/GBCC2023-main5.png' />
      <div class='full-width-inside-layout-container overflow-hidden border-b' style='max-width: calc(100vw - 0.5rem);'>
        <notices-rolling-banner
          background-color='#FFFFFF'
          class='full-width-inside-layout-container'
          style='height:56px; max-width:calc(100vw - 0.5rem);'
          custom-container-style='border-color: rgb(251 207 232);'
          custom-dot-style='background-color: rgb(251 207 232);' />
      </div>
      <div class='px-4 mt-10 mb-6'>
        <button
          @click='showSearchModal' 
          class='mx-auto py-3 px-8 text-sm rounded-full bg-white shadow opacity-70 uppercase text-gray-900 font-semibold flex flex-row gap-x-2 justify-center items-center'>
          Search
          <search-icon class='h-5' />
        </button>
      </div>
      <gbcc2023-main-items
        :items='items' 
        class='mb-4'/>
      <gbcc2023-main-sub-items 
        :items='subItems'
        class='my-4'/>
    </div>

  </div>
</template>

<script>
import { SearchIcon } from 'vue-tabler-icons'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import NoticesRollingBanner    from '@/components/NoticesRollingBanner.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import Gbcc2023MainItems       from '@/components/gbcc2023/Gbcc2023MainItems.vue'
import Gbcc2023MainSubItems    from '@/components/gbcc2023/Gbcc2023MainSubItems.vue'

export default {
  name: 'Main',
  components: {
    SearchIcon,
    ContentBackgroundCover,
    Gbcc2023MainItems,
    Gbcc2023MainSubItems,
    NoticesRollingBanner,
    WelcomeConferenceBanner,
  },
  computed: {
    items () {
      return [
        {
          title: 'Program',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Programs',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_program.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Votings',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'SessionVotings',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_votings.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Speakers',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Speakers',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_speakers.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Abstracts',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Abstracts',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_abstracts.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Floor Plan',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Gbcc2023FloorPlan',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_floor_plan.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'My Page',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'MyPage',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_my_page.png',
          iconImgCssClass: 'h-6 w-6',
        },
      ]
    },  
    subItems () {
      return [
        {
          size: 'large',
          type: 'internal_route',
          title: 'QR Code Scanner',
          titleCssStyle: 'color:#696969;',
          cssStyle: 'background:#FFF0EE;',
          routeName: 'StampEvent',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_barcode.png',
          iconImgCssStyle: 'color:#696969;',
        },
        {
          title: 'Youtube',
          type: 'external_link',
          titleCssStyle: 'color:#701515;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.youtube.com/channel/UCYi4I2P4oySdy149MzEggfA/featured',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_youtube.png',
          iconImgCssStyle: '',
        },
        {
          title: 'Instagram',
          type: 'external_link',
          titleCssStyle: 'color:#B91A53;',
          cssStyle: 'background:linear-gradient(96.72deg, #D105E622 5.49%, #FF2C3722 93.47%);',
          routeName: 'https://www.instagram.com/gbcc.tv/',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_instagram.png',
          iconImgCssStyle: '',
        },
        {
          title: 'Facebook',
          type: 'external_link',
          titleCssStyle: 'color:#153870;',
          cssStyle: 'background-color:#E1EBFB;',
          routeName: 'https://www.facebook.com/GBCCTV/',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_facebook.png',
          iconImgCssStyle: 'border-radius:8px;',
        },

      ]
    },
  },
  methods: {
    showSearchModal () {
      this.$modal.show('search-modal')
    },
  }
}
</script>
